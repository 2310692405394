<script setup lang="ts">
type ButtonVariant =
    | 'blue-outline'
    | 'blue'
    | 'blue-text'
    | 'gray-outline'
    | 'gray-text'
    | 'green-outline'
    | 'green-text'
    | 'green'
    | 'red'
    | 'white'
    | 'white-outline'
    | 'white-text';
const props = defineProps({
    /**
     * @description
     * Disables button through js/css, but keeps it perceivable for screen readers.
     */
    ariaDisabled: {
        default: false,
        type: Boolean,
    },
    customPadding: {
        default: false,
        type: Boolean,
    },
    /**
     * @description
     * Disables button
     *
     * ♿️ Not perceivable for `screen readers` any more.
     * Use `aria-disabled="true"` where applicable.
     *
     */
    disabled: {
        default: false,
        type: Boolean,
    },
    fullWidth: {
        default: false,
        type: Boolean,
    },
    hasIndicator: {
        default: false,
        type: Boolean,
    },
    isSmall: {
        default: false,
        type: Boolean,
    },
    /**
     * @description
     * Automatically resolves the component as `a` if provided
     *
     * Use this instead of `href` prop.
     * NuxtLinks can handle interal and external links.
     *
     */
    to: {
        default: undefined,
        type: String as PropType<string>,
    },
    type: {
        default: 'button',
        type: String as PropType<'button' | 'submit'>,
    },
    variant: {
        default: 'green',
        type: String as PropType<ButtonVariant>,
    },
});

const linkOrButton = computed(() =>
    props.to ? resolveComponent('NuxtLink') : 'button',
);

const isActive = computed(() => !(props.disabled || props.ariaDisabled));

const baseButton = ref<HTMLElement | null>(null);
watch(
    () => props.ariaDisabled,
    () => {
        if (props.ariaDisabled) {
            baseButton.value?.addEventListener('click', (ev) =>
                ev.preventDefault(),
            );
        }
    },
);
onUnmounted(() => {
    baseButton.value?.removeEventListener('click', (ev) => ev.preventDefault());
});
</script>

<template>
    <Component
        :is="linkOrButton"
        ref="baseButton"
        :type="props.to ? undefined : props.type"
        :to="props.to"
        :class="[
            $slots.icon && 'flex items-center gap-3',
            fullWidth && 'w-full',
            !fullWidth && 'w-fit',
            variant === 'blue-outline' &&
                `focus-visible:outline-blue-600 ${
                    isActive
                        ? ' text-blue-700 hover:bg-blue-700 hover:text-white hover:shadow-none active:bg-blue-800 active:text-white active:shadow-none'
                        : ' cursor-not-allowed text-gray-400'
                } ${customPadding ? '' : isSmall ? 'px-4 py-3' : 'px-7 py-4'}`,
            variant === 'blue' &&
                `focus-visible:outline-blue-600 ${
                    isActive
                        ? 'bg-blue-600 text-white hover:bg-blue-700 hover:text-white active:bg-blue-800'
                        : 'cursor-not-allowed bg-gray-400 text-white'
                } ${customPadding ? '' : isSmall ? 'px-4 py-3' : 'px-7 py-4'}`,
            variant === 'gray-outline' &&
                `focus-visible:outline-blue-600 ${
                    isActive
                        ? ' text-gray-900 hover:bg-blue-900 hover:text-white hover:shadow-none active:bg-green-900 active:text-white active:shadow-none'
                        : 'cursor-not-allowed bg-gray-400 text-white'
                } ${customPadding ? '' : isSmall ? 'px-4 py-3' : 'px-7 py-4'}`,
            variant === 'green-outline' &&
                `focus-visible:outline-blue-600 ${
                    isActive
                        ? ' text-green-800 shadow-outline hover:bg-green-800 hover:text-white hover:shadow-none active:bg-green-900 active:text-white active:shadow-none'
                        : ' cursor-not-allowed text-gray-400 shadow-outline'
                } ${customPadding ? '' : isSmall ? 'px-4 py-3' : 'px-7 py-4'}`,
            variant === 'green' &&
                `focus-visible:outline-blue-600 ${
                    isActive
                        ? 'bg-green-600 text-white hover:bg-green-800 hover:text-white active:bg-green-900 active:text-white'
                        : 'cursor-not-allowed bg-gray-400 text-white'
                } ${customPadding ? '' : isSmall ? 'px-4 py-3' : 'px-7 py-4'}`,
            variant === 'blue-text' &&
                `${
                    isActive
                        ? 'text-gray-900 underline hover:text-green-800 active:text-green-900'
                        : 'cursor-not-allowed text-gray-200 underline'
                }`,
            variant === 'gray-text' &&
                `${
                    isActive
                        ? 'text-gray-600 underline hover:text-green-800 active:text-green-900'
                        : 'cursor-not-allowed text-gray-200 underline'
                }`,
            variant === 'green-text' &&
                `${
                    isActive
                        ? 'text-green-800 hover:underline active:text-green-900'
                        : 'cursor-not-allowed text-gray-200 underline'
                }`,
            variant === 'red' &&
                `focus-visible:outline-blue-600 ${
                    isActive
                        ? 'bg-red-500 text-white hover:bg-red-700 hover:text-white active:bg-red-700 active:text-white'
                        : 'cursor-not-allowed bg-gray-400 text-white'
                } ${customPadding ? '' : isSmall ? 'px-4 py-3' : 'px-7 py-4'}`,
            variant === 'white-text' &&
                `focus-visible:outline-yellow-600 ${
                    isActive
                        ? 'text-white underline hover:no-underline active:text-gray-200 active:no-underline '
                        : 'cursor-not-allowed text-gray-200 underline'
                }`,
            variant === 'white-outline' &&
                `focus-visible:outline-yellow-600 ${
                    isActive
                        ? ' text-white hover:bg-green-600 hover:text-white hover:shadow-none active:bg-green-700 active:shadow-none'
                        : 'cursor-not-allowed bg-gray-400 text-white'
                } ${customPadding ? '' : isSmall ? 'px-4 py-3' : 'px-7 py-4'}`,
            variant === 'white' &&
                `focus-visible:outline-blue-600 ${
                    isActive
                        ? 'text-blue-900'
                        : 'cursor-not-allowed bg-gray-400 text-white'
                } ${customPadding ? '' : isSmall ? 'px-4 py-3' : 'px-7 py-4'}`,
        ]"
        class="inline-flex items-center justify-center rounded focus:outline-offset-4 focus-visible:outline focus-visible:outline-2"
        :disabled="disabled"
        :aria-disabled="ariaDisabled"
    >
        <span v-if="$slots.icon" class="relative">
            <span
                v-if="hasIndicator"
                class="absolute right-0 h-[5px] w-[5px] rounded-full bg-gray-900"
            />
            <slot name="icon" />
        </span>
        <slot />
    </Component>
</template>
